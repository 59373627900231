<script setup>
import {ref} from "vue";
import startupsService from "@/services/startups.service";
import {decodeDate} from "../../../../frontend/src/helpers/date";
import PrimaryButton from "../../../../frontend/src/components/ui/PrimaryButton.vue";

const startupId = ref(null);
const showModal = ref(false);
const calculating = ref(false);
const details = ref(null);
const logs = ref([]);
const coefficients = ref([]);
const maxValues = ref(null);
const result = ref(0);

const variables = [
    'tracker_clients',
    'views',
    'interacted_clients',
    'populated_fields_clients',
    'website_clients',
    'ppx_clients',
    'cb_rank',
];

const variables_names = [
    'Tracker Clients',
    'Views',
    'Interacted Clients',
    'Clients with populated fields',
    'Website clients',
    'PPX clients',
    'CB rank',
];

const colors = [
   '#FF5733', // Red
   '#10571c', // Green
   '#3357FF', // Blue
   '#FF33A1', // Pink
   '#FF8C33', // Orange
   '#31ccc4', // Cyan
   '#8C33FF'  // Purple
];

async function getData() {
    const {data} = await startupsService.getIRLScoreDetails(startupId.value);
    details.value = data[0];
    logs.value = data[1];
    coefficients.value = data[2];
}

async function calculate() {
    calculating.value = true;
    const {data} = await startupsService.calculateIrlScore(startupId.value);
    maxValues.value = data;

    await getData();
    calculating.value = false;

    result.value = 0;

    for (let i = 0; i < variables.length; i++) {
        if (maxValues.value[i] === 0) {
            continue;
        }

        if (variables[i] === 'cb_rank') {
            result.value += (1 - (details.value[variables[i]] || 0) / maxValues.value[i]) * coefficients.value[variables[i]];
        } else {
            result.value += (details.value[variables[i]] || 0) / maxValues.value[i] * coefficients.value[variables[i]];
        }
    }
}

function open(id) {
    startupId.value = id;
    details.value = null;
    showModal.value = true;
    maxValues.value = null;

    getData();
}

defineExpose({
    open,
});
</script>

<template>
    <b-modal v-model="showModal" title="Startup IRL Score" modal-class="modal-w-xxl" hide-footer>
        <div class="mb-3">
            <table v-if="details">
                <thead>
                <tr>
                    <th></th>
                    <th v-for="(variable, index) in variables_names" :key="variable" :style="{'color': colors[index]}">
                        {{ variable }}
                    </th>

                    <th>
                        Absolute Score
                    </th>

                    <th>
                        IRL Score
                    </th>
                </tr>
                </thead>

                <tbody>
                <tr>
                    <td>
                        <b>Values</b>
                    </td>

                    <td v-for="(variable, index) in variables" :key="variable" :style="{'color': colors[index]}">
                        {{ details[variable] || 0 }}
                    </td>

                    <td>
                        <b>{{ details.irl_score_absolute || 0 }}</b>
                    </td>

                    <td>
                        <b>{{ details.irl_score || 0 }}</b>
                    </td>

                </tr>
                <tr>
                    <td>
                        <b>Coefficients</b>
                    </td>

                    <td v-for="(variable, index) in variables" :key="variable" :style="{'color': colors[index]}">
                        {{ coefficients[variable] }}
                    </td>

                    <td></td>
                    <td></td>
                </tr>

                <tr v-if="maxValues">
                    <td>
                        <b>Max Values</b>
                    </td>

                    <td v-for="(variable, index) in variables" :key="variable" :style="{'color': colors[index]}">
                        {{ maxValues[index] }}
                    </td>

                    <td></td>
                    <td></td>
                </tr>
                </tbody>
            </table>

            <p v-if="maxValues">
                <b>Formula:</b>
                <span v-for="(variable, index) in variables" :key="variable" :style="{'color': colors[index]}">
                    <template v-if="variable === 'cb_rank'">
                        (1 - {{ details[variable] || 0 }} / {{ maxValues[index] }}) * {{ coefficients[variable] }}
                    </template>

                    <template v-else>
                        {{ details[variable] || 0 }} / {{ maxValues[index] }} * {{ coefficients[variable] }} +
                    </template>
                </span>
                = {{ result }}
            </p>

            <h5>Calculation History</h5>

            <table>
                <thead>
                <tr>
                    <th>
                        Date
                    </th>
                    <th>
                        Prev score
                    </th>
                    <th>
                        New score
                    </th>
                </tr>
                </thead>

                <tbody>
                <tr v-for="log in logs" :key="log.id">
                    <td>
                        {{ decodeDate(log.created_at) }}
                    </td>
                    <td>
                        {{ log.prev_score }}
                    </td>
                    <td>
                        {{ log.new_score }}
                    </td>

                </tr>
                </tbody>
            </table>

            <div class="text-center">
                <PrimaryButton
                    caption="Calculate"
                    loading-caption="Calculating..."
                    :loading="calculating"
                    @click="calculate"
                />
            </div>
        </div>
    </b-modal>
</template>

<style scoped>
    table {
        text-align: center;
        margin-bottom: 15px;

        td, th {
            border: 1px solid;
            padding: 0 5px;
        }
    }
</style>
