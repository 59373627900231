import axios from '@/axios';

const RESOURCE_NAME = '/admin/saved-searches';

export default {
    search(enterpriseId, search) {
        return axios.get(`${RESOURCE_NAME}/search?enterprise_id=${enterpriseId}&search=${search}`);
    },

    duplicate(searchId, enterpriseId, data) {
        return axios.post(`${RESOURCE_NAME}/duplicate?id=${searchId}&enterprise_id=${enterpriseId}`, {data});
    },
}
